import jsPDF from 'jspdf'
import displayToast from './displayToast'

function urlToElement(url: string): Promise<HTMLImageElement> {
  return new Promise((resolve) => {
    const img = new Image()
    img.src = url
    img.onload = () => resolve(img)
  })
}

export default async function downloadDocumentPdf(
  urls: Array<string>,
  name = 'crewdentials-export'
): Promise<void> {
  const images = await Promise.all(urls.map((url) => urlToElement(url)))
  const doc = new jsPDF({
    orientation: images[0].width > images[0].height ? 'l' : 'p',
    unit: 'px',
    format: [images[0].width, images[0].height]
  })
  images.forEach((img, index) => {
    index !== 0 && doc.addPage([img.width, img.height], img.width > img.height ? 'l' : 'p')
    doc.addImage(img, 'JPEG', 0, 0, img.width, img.height, undefined, 'FAST')
  })
  doc.save(`${name}.pdf`)
  displayToast(`Downloaded ${name}.pdf`)
  return
}
