<template>
  <DialogPanel
    class="relative rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-screen-xl"
  >
    <ModalHeader title="Complete document" @close="$emit('dismiss')" />

    <div class="p-4 sm:p-6">
      <FormKit
        type="form"
        :actions="false"
        :incomplete-message="false"
        #default="{ value, state: { valid, loading } }"
        v-model="docForm"
        @submit="completeDocument"
      >
        <div class="grid grid-cols-1 sm:grid-cols-2 gap-6 mt-3">
          <DocumentImages
            :doc-id="doc.id"
            :image-paths="doc.imagePaths!"
            :image-urls="localImageUrls!"
            :scanning="!docForm?.categoryId && scanLoading"
          />
          <div>
            <!-- @vue-ignore -->
            <FormKit
              type="autocomplete"
              name="categoryId"
              label="Document type"
              :disabled="scanLoading || loading"
              placeholder="Start typing..."
              :options="searchDocCategories"
              validation="required"
              selection-appearance="option"
            >
              <!-- @vue-ignore -->
              <template #selection="{ option, classes }">
                <div :class="classes.selection">
                  <div class="formkit-option capitalize">
                    {{ $t(`docCategories.${option.value}.name`) }}
                  </div>
                </div>
              </template>
              <!-- @vue-ignore -->
              <template #option="{ option }">
                <div class="formkit-option capitalize">
                  {{ $t(`docCategories.${option.value}.name`) }}
                  <span class="italic" v-if="option.matches?.length"
                    >({{ option.matches.join(', ') }})</span
                  >
                </div>
              </template>
            </FormKit>
            <div v-if="scanLoading">
              <InfoNote text="We are scanning your document"></InfoNote>
              <FormKit type="hidden" validation="required" />
            </div>
            <component
              :is="
                !!docForm?.categoryId ? docCategories.get(docForm.categoryId)?.component : undefined
              "
              v-bind="{ value }"
            />
            <InfoNote
              v-if="!docForm?.categoryId && !!scanResult"
              text='Try to categorise your document accurately - start typing to see our suggestions. <a target="_blank" class="font-medium text-blue-700 underline hover:text-blue-600" href="https://workspace-crewdentials.tawk.help/article/supported-document-types">Find out more</a>'
            />
          </div>
        </div>
        <div class="grid grid-cols-2 gap-x-4 mt-10">
          <InfoNote v-if="submitAttempted" class="col-span-2 mb-4" color="red"
            >Are you sure this information is correct? Once saved, it cannot be edited.</InfoNote
          >
          <AppButton
            color="light"
            @clicked="$emit('dismiss')"
            data-test-id="complete-document-cancel-button"
            >Cancel</AppButton
          >
          <div class="h-[40px]" :class="valid ? '' : 'opacity-50'">
            <FormKit
              type="submit"
              :classes="{ input: 'h-[40px]' }"
              :label="submitAttempted ? `Yes, I'm sure` : 'Finish'"
            />
          </div>
        </div>
      </FormKit></div
  ></DialogPanel>
</template>

<script setup lang="ts">
import { ref, onMounted, markRaw, watch } from 'vue'
import type { Component } from 'vue'
import CertificateForm from '@/components/CertificateForm.vue'
import InfoNote from '@/components/InfoNote.vue'
import PassportForm from '@/components/PassportForm.vue'
import IdForm from '@/components/IdForm.vue'
import { useModalStore } from '@/stores/modal'
import Fuse from 'fuse.js'
import { DialogPanel } from '@headlessui/vue'
import type {
  DocumentData,
  CertificateFormData,
  PassportFormData,
  IdFormData,
  OtherDocFormData,
  DocumentTypes
} from '@/types/types'
import { useI18n } from 'vue-i18n'
import ModalHeader from '@/components/ModalHeader.vue'
import AppButton from '@/components/AppButton.vue'
import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query'
import { supabase } from '@/lib/supabaseClient'
import { v4 as uuidv4 } from 'uuid'
import axios from 'axios'
import OtherDocForm from '@/components/OtherDocForm.vue'
import DocumentImages from '@/components/DocumentImages.vue'

const props = defineProps<{
  localImageUrls?: string[]
  doc: DocumentData
}>()

const workspaceId = props.doc.workspaceId as string

const emit = defineEmits<{
  (event: 'dismiss', documents?: DocumentData[]): void
  (event: 'setBeforeDismiss', callback: (() => Promise<boolean>) | undefined): void
  (event: 'setAfterDismiss', callback: (() => Promise<boolean>) | undefined): void
}>()

const queryClient = useQueryClient()
const { presentLoader, dismissLoader, presentConfirm } = useModalStore()

const docCategories = ref(
  new Map<
    string,
    {
      id: string
      name: string
      searchPhrases: string[]
      component: Component
    }
  >()
)
let fuse: Fuse<any>
const docForm = ref()

const submitAttempted = ref(false)

const completeDocument = async () => {
  if (!submitAttempted.value) {
    submitAttempted.value = true
    return
  }
  let newDocuments: DocumentData[]
  const formValue = docForm.value!
  const type: DocumentTypes = formValue.categoryId
  if (type === 'CERTIFICATE') {
    const certificateForm = {
      id: props.doc.id,
      originalFilePaths: props.doc.originalFilePaths,
      imagePaths: props.doc.imagePaths,
      certifications: formValue.certifications,
      documentRef: formValue.documentRef,
      certificateIssuerId: formValue.certificateIssuerId
    } as CertificateFormData
    newDocuments = (await saveCertificatesSeparately(certificateForm)) as DocumentData[]
  } else {
    let doc
    if (type === 'PASSPORT') {
      doc = {
        categoryId: 'PASSPORT',
        documentRef: formValue.documentRef,
        issueDate: formValue.issueDate,
        expiryDate: formValue.expiryDate,
        passportNationality: formValue.passportNationality
      } as PassportFormData
    }
    if (type === 'OTHER') {
      doc = {
        categoryId: 'OTHER',
        name: formValue.name,
        issueDate: formValue.issueDate || null,
        expiryDate: formValue.expiryDate || null
      } as OtherDocFormData
    }
    if (type === 'ID') {
      doc = {
        categoryId: 'ID',
        documentRef: formValue.documentRef,
        name: formValue.name,
        issueDate: formValue.issueDate || null,
        expiryDate: formValue.expiryDate || null,
        issuerCountry: formValue.issuerCountry
      } as IdFormData
    }
    if (!doc) throw new Error('Unsupported type')
    const newDocument = await updateDoc({ ...doc, status: 'COMPLETE', id: props.doc.id })
    newDocuments = [newDocument as DocumentData]
  }
  emit('setAfterDismiss', undefined)
  emit('dismiss', newDocuments)
}

const { data: scanResult, isLoading: scanLoading } = useQuery({
  queryKey: ['docScans', props.doc.id],
  staleTime: Infinity,
  retry: false,
  refetchOnWindowFocus: false,
  queryFn: async () => {
    const savedScan = props.doc.data?.scanResult
    if (!!savedScan) {
      return savedScan
    } else {
      const { data: scanData } = await axios.post(
        `${import.meta.env.VITE_API_BASE_URL}/w/${workspaceId}/documentScanner`,
        { documentId: props.doc.id },
        { timeout: 10000 }
      )
      return scanData
    }
  }
})

const { mutateAsync: updateDoc } = useMutation({
  mutationFn: async (updatedDoc: Partial<DocumentData>) => {
    const { id, ...updates } = updatedDoc
    return await supabase
      .from('Documents')
      .update(updates)
      .eq('id', id!)
      .select()
      .single()
      .then(({ data }) => data)
  },
  onSuccess: () => {
    queryClient.invalidateQueries(['workspaces', workspaceId, 'documents'])
  }
})

const { mutateAsync: createDoc } = useMutation({
  mutationFn: async (newDoc: Partial<DocumentData>) => {
    return supabase
      .from('Documents')
      .insert(newDoc as any)
      .select()
      .single()
      .then(({ data }) => data)
  },
  onSuccess: () => {
    queryClient.invalidateQueries(['workspaces', workspaceId, 'documents'])
  }
})

const { mutateAsync: saveCertificatesSeparately } = useMutation({
  mutationFn: async (certificateForm: any) => {
    const { certifications, ...documentFields } = certificateForm
    const docData = {
      id: props.doc.id,
      imagePaths: props.doc.imagePaths,
      originalFilePaths: props.doc.originalFilePaths,
      categoryId: 'CERTIFICATE',
      documentRef: documentFields.documentRef,
      certificateIssuerId: documentFields.certificateIssuerId,
      data: { scanResult: scanResult.value },
      workspaceId
    }

    const [firstCertification, ...otherCertifications] = certifications

    function getCertTypeFields(certification: CertificateFormData['certifications'][0]) {
      if (!!certification.otherCertType) return { name: certification.otherCertType }
      else
        return {
          certificateTypeId:
            certification.certificateTypeId || certification.groupOrCertificateTypeId
        }
    }

    const [originalDoc, ...newDocuments] = await Promise.all([
      updateDoc({
        ...docData,
        ...getCertTypeFields(firstCertification),
        categoryId: 'CERTIFICATE',
        expiryDate: firstCertification.expiryDate || null,
        issueDate: firstCertification.issueDate || null,
        status: 'COMPLETE'
      }),
      ...otherCertifications.map(async (certification: any) => {
        const docId = uuidv4()

        const imagePaths: string[] = JSON.parse(
          JSON.stringify(docData.imagePaths).replace(new RegExp(docData.id, 'g'), docId)
        )
        const originalFilePaths: string[] = JSON.parse(
          JSON.stringify(docData.originalFilePaths).replace(new RegExp(docData.id, 'g'), docId)
        )

        await createDoc({
          ...docData,
          id: docId,
          status: 'UPLOADING',
          categoryId: 'CERTIFICATE',
          ...getCertTypeFields(certification),
          expiryDate: certification.expiryDate || null,
          issueDate: certification.issueDate || null,
          imagePaths,
          originalFilePaths
        })

        const originalCopyFilePaths = [...docData.imagePaths!, ...docData.originalFilePaths!]
        const newCopyFilePaths = [...imagePaths, ...originalFilePaths]

        await Promise.all(
          originalCopyFilePaths.map((originalCopyPath, index) =>
            supabase.storage.from('documents').copy(originalCopyPath, newCopyFilePaths[index])
          )
        )

        return await updateDoc({
          id: docId,
          status: 'COMPLETE'
        })
      })
    ])

    // Get crew linked to original doc
    const { data: linkedCrew } = await supabase
      .from('DocumentsCrewProfiles')
      .select()
      .match({ documentId: props.doc.id, workspaceId })

    if (!!linkedCrew) {
      // Link all new docs to same crew
      await Promise.all(
        linkedCrew.map(async ({ crewProfileId }) =>
          Promise.all(
            newDocuments.map(({ id: documentId }) =>
              supabase.from('DocumentsCrewProfiles').insert({
                workspaceId,
                documentId,
                documentWorkspaceId: workspaceId,
                crewProfileId
              })
            )
          )
        )
      )
    }

    await queryClient.invalidateQueries(['workspaces', workspaceId, 'documents'])
    return [originalDoc, ...newDocuments]
  },
  onSuccess: () => {
    queryClient.invalidateQueries(['workspaces', workspaceId, 'documents'])
  }
})

watch(
  scanResult,
  (value) => {
    if (!!value) {
      const { certifications, ...scanResult }: any = value
      if (!!certifications?.length) {
        scanResult.certifications = certifications.map(({ id, ...certification }: any) => ({
          groupOrCertificateTypeId: id,
          ...certification
        }))
      }
      docForm.value = JSON.parse(
        JSON.stringify({
          categoryId: scanResult.categoryId,
          certifications: scanResult.certifications,
          documentRef: scanResult.documentRef,
          issueDate: scanResult.issueDate,
          expiryDate: scanResult.expiryDate,
          issuerCountry: scanResult.issuerCountry,
          certificateIssuerId: scanResult.certificateIssuerId,
          passportNationality: scanResult.passportNationality
        })
      )
    }
  },
  { immediate: true }
)

onMounted(async () => {
  emit('setAfterDismiss', async () => {
    const shouldKeep = await presentConfirm({
      title: `Do you want to keep this document?`,
      message: `You haven't completed this document, do you want to keep it?`,
      type: 'info',
      confirmText: `Yes, I'll complete it later`,
      cancelText: `No, delete it`
    })
    if (shouldKeep === false) {
      presentLoader()
      const { data } = await axios.post(
        `${import.meta.env.VITE_API_BASE_URL}/w/${workspaceId}/documents/deleteIncomplete/${props.doc.id}`
      )
      dismissLoader()
      if (data) {
        queryClient.invalidateQueries({ queryKey: ['workspaces', workspaceId, 'documents'] })
        queryClient.invalidateQueries({ queryKey: ['workspaceDocsByProfile'], exact: false })
        queryClient.removeQueries({ queryKey: ['doc', props.doc.id] })
        queryClient.invalidateQueries({
          queryKey: ['accessLink', 'crewProfileId', 'incompleteDocs']
        })
      }
    }
    return true
  })

  getDocCategories()
})

const getDocCategories = async () => {
  const { tm } = useI18n()
  const categories = tm('docCategories')

  const formComponents: { [key: string]: Component } = {
    PASSPORT: markRaw(PassportForm),
    CERTIFICATE: markRaw(CertificateForm),
    ID: markRaw(IdForm),
    OTHER: markRaw(OtherDocForm)
  }

  Object.entries(categories).forEach(([key, value]) =>
    docCategories.value.set(key, {
      ...(value as any),
      id: key,
      component: formComponents[key]
    })
  )

  fuse = new Fuse([...docCategories.value.values()], {
    keys: ['searchPhrases', 'name'],
    includeMatches: true
  })
}

const searchDocCategories = ({ search }: any) => {
  if (!!search) {
    const results = fuse.search(search)
    if (results.length) {
      return results.map(({ item, matches }) => ({
        value: item.id,
        matches: matches!.filter(({ key }) => key === 'searchPhrases').map((match) => match.value)
      }))
    }
    return [...docCategories.value.values()]
      .filter(({ id }) => id === 'other')
      .map(({ id, name }) => ({ label: name, id, value: id }))
  }
  return [...docCategories.value.values()].map((docType) => ({
    value: docType.id
  }))
}
</script>
