<template>
  <vue-easy-lightbox
    :moveDisabled="true"
    :rotateDisabled="true"
    :visible
    @hide="visible = false"
    :imgs="images"
    :index
  >
  </vue-easy-lightbox>
</template>
<script setup lang="ts">
import { ref } from 'vue'
import VueEasyLightbox from 'vue-easy-lightbox'

const visible = ref(false)
const index = ref(0)

const props = defineProps<{ images: any[] }>()

const show = (initialIndex?: number) => {
  index.value = initialIndex || 0
  visible.value = true
}

defineExpose({ show })
</script>

<style>
.vel-modal {
  background: #000 !important;
}
.vel-img-title {
  font-size: 20px !important;
  padding: 10px 20px !important;
  border-radius: 0.75rem !important;
  background: #000 !important;
  color: #fff !important;
  opacity: 1 !important;
}
</style>
